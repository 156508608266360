<template>
  <StandupContent :selectedOffice="selectedOffice" :isHistoryMode="false" />
</template>

<script>
import StandupContent from "@/components/Standup/StandupContent";

export default {
  name: "Standup",
  components: { StandupContent },
  computed: {
    selectedOffice() {
      return this.$route.params.office;
    }
  }
};
</script>

<style scoped></style>
