<template>
  <div class="standup-container">
    <button
      class="temporary"
      v-on:click="setRandomEmployee"
      v-if="!randomizerFinished"
    >
      NEXT
    </button>

    <!-- // removing the cheering button until further notice -->
    <!--<button class="cheering" v-on:click="playCheering">&#128079;</button>-->

    <img src="../../assets/logo.svg" alt="" class="logo-bg" />
    <div key="1" class="name" v-if="currentViewMode === viewModes[0]">
      {{ selectedEmployee.firstName }}
    </div>
    <div key="2" class="randomize-main" v-if="currentViewMode === viewModes[0]">
      <div v-if="!taskRecord" class="no-record">
        <img src="../../assets/sad-emoji.png" alt="" />
        <p>
          {{ selectedEmployee.firstName }} didn't enter anything this week...
        </p>
      </div>
      <div v-else class="content">
        <div class="left">
          <div class="hour-info">
            <div class="billable">
              <p>Billable Days:</p>
              <p>{{ billableDays }}</p>
            </div>

            <div class="available">
              <p>Available Days:</p>
              <p>{{ availableDays }}</p>
            </div>
          </div>
          <div class="working-on">
            <h4>Working on:</h4>
            <p>{{ workingOnText }}</p>
          </div>
          <div class="need-help-with" v-if="needHelpWithText">
            <h4>I need help with:</h4>
            <p>{{ needHelpWithText }}</p>
          </div>
        </div>
        <div class="right">
          <div v-if="imageUrlWithConstraints !== ''" class="image-container">
            <img
              :src="imageUrlWithConstraints"
              alt="TaskImage"
              class="working-image"
            />
          </div>
        </div>
      </div>
    </div>
    <div key="3" class="spinner" v-else-if="currentViewMode === viewModes[1]">
      ?
    </div>
    <div v-else-if="currentViewMode === viewModes[2]" class="done">
      <h2>Great job! You're all done!</h2>
    </div>
    <div v-else-if="loading === true" class="get-ready">
      <h2>Loading.. People.. Beep Boop..</h2>
    </div>
    <div v-else-if="people.length === 0 && loading === false" class="get-ready">
      <h2>Catastrophic failure!!! Signal 🦸🏼‍ Patrick....️</h2>
    </div>
    <div key="4" class="get-ready" v-else>
      <h2>Get ready to be randomized...!</h2>
    </div>
  </div>
</template>

<script>
import { fetchPeople, getAllVacations, getTaskRecord } from "@/api";

export default {
  props: {
    selectedOffice: String
  },

  data() {
    return {
      selectedEmployee: {},
      taskRecord: {},
      isSpinning: false,
      viewModes: ["normal", "spinning", "finished", "notStarted"],
      currentViewMode: "spinning",
      catImage: "",
      loading: true,
      people: [],
      selectableNumbers: [],
      randomizedEmployee: null,
      randomizeButtonDisabled: false,
      randomizerFinished: false,
      cheering: null
    };
  },

  computed: {
    workingOnText() {
      if (this.taskRecord) return this.taskRecord.workingOn;
      else return "";
    },

    needHelpWithText() {
      if ( this.taskRecord && typeof this.taskRecord.needHelpWith !== 'undefined' ) {
        return this.taskRecord.needHelpWith;
      } else {
        return "";
      }
    },

    imageUrlWithConstraints() {
      if (
        this.taskRecord &&
        this.taskRecord.imageUrl &&
        this.taskRecord.imageUrl !== ""
      ) {
        let splitUrl = this.taskRecord.imageUrl.split("/upload");
        const imgUrl = `${splitUrl[0]}/upload/q_80,w_800/${splitUrl[1]}`;
        const cacheImg = new Image();
        cacheImg.src = imgUrl;
        return imgUrl;
      } else if (this.catImage !== "") return this.catImage;
      else return "";
    },

    billableDays() {
      if (this.taskRecord && this.taskRecord.billableDays) {
        return this.taskRecord.billableDays;
      } else {
        return "0";
      }
    },

    availableDays() {
      if (this.taskRecord && this.taskRecord.availableDays !== undefined) {
        return this.taskRecord.availableDays;
      } else {
        return "0";
      }
    }
  },
  methods: {
    async updateEmployee(employee) {
      this.catImage = "";
      if (employee.email) {
        this.currentViewMode = this.viewModes[1];
        await this.getCatImage();

        this.selectedEmployee = employee;
        const docId = this.currentWeekRecordId(employee.email);
        this.taskRecord = await getTaskRecord(docId);

        setTimeout(async () => {
          this.currentViewMode = this.viewModes[0];
        }, 1000);
      } else if (employee.finished) {
        this.currentViewMode = this.viewModes[2];
      } else if (employee.finished === false) {
        this.currentViewMode = this.viewModes[3];
      }
    },

    async getCatImage() {
      let response = await fetch(
        "https://api.thecatapi.com/v1/images/search?limit=1&order=Random&size=large&page=0&category_ids=4&sub_id=demo-adefd4"
      );
      let data = await response.json();
      const cacheImg = new Image();
      cacheImg.src = data[0].url;
      this.catImage = data[0].url;
    },
    playCheering() {
      this.cheeringAudio.currentTime = 0;
      this.cheeringAudio.play();
    },

    handleKeyInput(e) {
      if (!this.randomizerFinished) {
        if (e.code === "ArrowRight" || e.code === "Enter") {
          this.setRandomEmployee();
        } else if (e.code === "Space") {
          e.preventDefault();
          this.playCheering();
        }
      }
    },

    setRandomEmployee() {
      if (!this.randomizerFinished && !this.randomizeButtonDisabled) {
        this.randomizeButtonDisabled = true;
        const rand = this.selectableNumbers[
          Math.floor(Math.random() * this.selectableNumbers.length)
        ];

        if (this.selectableNumbers.length !== 0) {
          this.updateEmployee(this.people[rand]);

          // remove the new number from selectable numbers
          this.selectableNumbers = this.selectableNumbers.filter(
            el => el !== rand
          );
        } else {
          // Standup is finished
          this.currentViewMode = this.viewModes[2];
        }

        setTimeout(() => {
          this.randomizeButtonDisabled = false;
        }, 2000);
      }
    }
  },

  async created() {
    this.cheeringAudio = new Audio();
    this.cheeringAudio.src = "/cheering.mp3";

    this.currentViewMode = this.viewModes[3];

    this.loading = true;

    // get users who are on vacation / registered as away
    const allVacations = await getAllVacations();
    const vacationEmails = allVacations
      .filter(e => e.onVacation)
      .map(e => e.email);

    const employees = await fetchPeople(this.selectedOffice);

    // filter out those that are on vacation
    this.people = employees.filter(p => !vacationEmails.includes(p.email));
    this.selectableNumbers = [...Array(this.people.length).keys()];

    this.loading = false;
  },

  mounted() {
    document.body.addEventListener("keyup", this.handleKeyInput);
  },

  destroyed() {
    document.body.removeEventListener("keyup", this.handleKeyInput);
  }
};
</script>

<style scoped>
.temporary,
.cheering {
  position: absolute;
  bottom: 5rem;
  right: 1rem;
  width: 100px;
  height: 40px;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  opacity: 0.3;
}

.temporary:hover {
  opacity: 1;
}

.cheering {
  bottom: 4rem;
  right: 5rem;
  font-size: 3rem;
  height: 80px;
}
.cheering:hover {
  opacity: 0.8;
}
.no-record {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.no-record > img {
  width: 50%;
  z-index: 2;
  margin-bottom: 1rem;
}

.no-record > p {
  font-size: 3rem;
  font-weight: normal;
}

.logo-bg {
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(150px, -150px);
  pointer-events: none;
  width: auto;
  height: 800px;
  opacity: 0.1;
}

.randomize-main {
  display: flex;
  width: 100%;
  color: rgb(25, 22, 23);
  max-width: 90vw;
}
.content {
  display: flex;
  width: 100%;
  color: rgb(25, 22, 23);
  gap: 2rem;
}

.right {
  flex: auto;
  max-height: 70vh;
}

.left {
  flex: 0 1 500px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.hour-info {
  /* text-align: center; */
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 1em 0 1rem;
  flex-direction: column;
}

.billable,
.available {
  display: flex;
  color: #272946;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  font-size: 1.25vw;
}

.billable > p:first-child,
.available > p:first-child {
  margin-right: 0.5rem;
  font-weight: bold;
}

.standup-container {
  /*background-image: linear-gradient(#9d6092, #a15b7b);*/
  background: #e8f8ef;
  flex: 1 1 auto;
  padding: 4rem;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.get-ready {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-10deg);
  color: #421741;
}

.get-ready > h2 {
  font-size: 4rem;
  text-align: center;
  animation: pulse 1s infinite;
}

.name {
  font-size: 5rem;
  text-align: left;
  color: #272946;
  width: 100%;
  max-width: 90vw;
}

.working-on,
.need-help-with {
  margin-top: 3rem;
  font-size: 2rem;
  text-align: left;
}

.working-on > h4,
.need-help-with > h4 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  color: #272946;
}

.working-on > p,
.need-help-with > p{
  min-height: 2rem;
}

.image-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex: auto;
  height: 100%;
}

.working-image {
  max-width: 100%;
  object-fit: contain;
  border-radius: 4px;
  background: #f7f7f71c;
  z-index: 2;
  flex: auto;
}
.spinner {
  font-size: 70vh;
  animation: spinner 2s infinite;
  color: #421741;
  z-index: 2;
}

.done {
  color: #421741;
  height: 80vh;
  display: flex;
  align-items: center;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  80% {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}
</style>
